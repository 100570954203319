import './../css/app.pcss';

// Stores
import CustomerStore from '/src/vue/stores/customer.ts';

// Modules
import csrf from '/src/ts/commons/csrf.ts';
import header from '/src/ts/commons/header.ts';
import footer from '/src/ts/commons/footer.ts';

export default function init() {
    csrf();
    // Create our store
    const store = CustomerStore();
    // Header Vue Instance
    header(store);
    // Footer Vue Instance
    footer();
}

init();
